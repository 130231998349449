//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

body .simple-modal {
  height: auto;
  overflow-y: auto !important;
}

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  -webkit-overflow-scrolling: touch;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    @include translate(0, -25%);
    @include transition-transform(0.3s ease-out);
  }

  &.in .modal-dialog {
    @include translate(0, 0)
  }
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

// Actual modal
.modal-content {
  position: relative;
  background-color: $modal-content-bg;
  border: 1px solid $modal-content-fallback-border-color; //old browsers fallback (ie8 etc)
  border: 1px solid $modal-content-border-color;
  border-radius: $border-radius-large;
  @include box-shadow(0 3px 9px rgba(0, 0, 0, .5));
  background-clip: padding-box;
  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-background;
  background-color: $modal-backdrop-bg;

  // Fade for backdrop
  &.fade {
    @include opacity(0);
  }

  &.in {
    @include opacity($modal-backdrop-opacity);
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  padding: $modal-title-padding;
  border-bottom: 1px solid $modal-header-border-color;
  @include clearfix;
}

// Close icon
.modal-header .close {
  margin-top: -2px;
}

// Title text within header
.modal-title {
  margin: 0;
  line-height: $modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  padding: $modal-inner-padding;
}

// Footer (for actions)
.modal-footer {
  padding: $modal-inner-padding;
  text-align: right; // right align buttons
  border-top: 1px solid $modal-footer-border-color;
  @include clearfix; // clear it in case folks use .pull-* classes on buttons

  // Properly space out buttons
  .btn+.btn {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }

  // but override that for button groups
  .btn-group .btn+.btn {
    margin-left: -1px;
  }

  // and override it for block buttons as well
  .btn-block+.btn-block {
    margin-left: 0;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@media (min-width: $screen-sm-min) {

  // Automatically set modal's width for larger viewports
  .modal-dialog {
    width: $modal-md;
    margin: 30px auto;
  }

  .modal-content {
    @include box-shadow(0 5px 15px rgba(0, 0, 0, .5));
  }

  // Modal sizes
  .modal-sm {
    width: $modal-sm;
  }
}

@media (min-width: $screen-md-min) {
  .modal-lg {
    width: $modal-lg;
  }
}

.simple-modal {
  position: fixed;
  display: none; 
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
}

.simple-modal__content {
  background-size: cover;
  max-height: 430px;
  max-width: 625px;
  background-position-x: -32px;
}

@media (min-width: $screen-sm-min) {
  .simple-modal__content {
    // max-height: 451px;
    // max-width: 800px;
    max-height: 570px;
    max-width: 840px;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position-x: initial;
    background-position-x: right;
    display: table !important;
  }
}

.simple-modal {
  --gutter: 14px;
  // --modal-color: #800000;
  // --soft-color: #fafafa;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  position: fixed;
  // background-color: rgba(0, 0, 0, .7);
  padding-top: 2%;
  padding-bottom: 2% !important;
  display: flex;



  &__content {

    width: 95vw;
    height: 90vh;

    position: relative;
    overflow: hidden;
    border-radius: 4px;
    margin: auto;
    // background-color: #ffffff;
    display: flex;
    flex-direction: column;

    .content {
      h3 span {
        color: red !important;
      }
      h3 {
        font-family: 'Whitney';
        color: black;
        justify-content: center;
        text-align: center;
        font-size: 18px;
        margin-bottom: 1.8rem;
        text-align: justify;
        letter-spacing: 0.5px;
        padding: 0px 1rem;
        font-weight: bold;
      }
      p {
        font-family: 'Whitney';
        color: black;
        padding: 0px 1rem;
        text-align: center;
        font-size: 1.55rem;
        margin-bottom: 1.5rem;
        text-align: justify;
      }
      a {
        font-family: 'Whitney';
       }

      @media (min-width: $screen-sm-min) {
        h3, p {
          padding: 0px 0.5rem;
        }
      }
    }

    header {
      min-height: 60px;
      height: 60px;
      color: black;
      background-color: var(--modal-color);
      display: grid;
      padding-left: var(--gutter);
      align-items: center;
      grid-template-columns: auto 60px;

      h4 {
        margin: 0;
        text-align: left;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-size: 20px;
        opacity: .8;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }

    .popup-title {
      color: black !important;
      font-family: 'Whitney' !important;
      text-align: center;
      font-size: 1.6rem;
      span {
        font-size: 1.3rem;;
      }
    }

    @media only screen and (min-width: 768px) {
      .popup-title {
        opacity: 0;
      }
      .content {
        margin-top: 10rem;
      }
    }

    .modal-main {
      flex: 1;
      text-align: left;
      overflow: auto;
      padding: var(--gutter);

      .title {
        display: flex;
        justify-content: center;
        padding: 0px 1rem;
        margin-bottom: 0rem;
        margin-top: 8rem;
      }

      @media (min-width: $screen-sm-min) {
        .title {
          padding: 0px;
          justify-content: flex-end;
          margin-top: 0rem;
          margin-bottom: 4rem;

        }
      }
    }

    footer {
      height: auto;
      text-align: right;
      border-top: solid 1px #cccccc;
      padding: var(--gutter);
      background-color: #ffffff;
      background-color: var(--soft-color);

      button,
      input {
        margin: 0;

        &:not(:last-child) {
          margin-right: var(--gutter);
        }
      }
    }
  }
}

.close-button {
  margin-top: 3rem;
}

// Iphone X
@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) { 
      .simple-modal__content {
      height: 80vh !important;
      }
  }

// Mobile
@media only screen and (max-width: 600px) {
  .simple-modal__content {
      background-repeat:no-repeat;
      background-size: cover;
      width: 98vw;
      // height: 95vh;
      height: auto;
      background-position: 3%;
  }
  .content {
    margin-top: 2rem;
  }
}

/*----------------
  NOTICE AGM
----------------*/

.notice {
  border: 2px solid #1e3a6d;
  margin-top: 5px;
  h3 span {
    color: red !important;
  }
  h3 {
    font-family: 'Whitney';
    color: black;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    margin-bottom: 1.8rem;
    text-align: justify;
    letter-spacing: 0.2px;
    padding: 0px 2rem;
    font-weight: bold;
    margin-top: 17px;
  }
  p {
    font-family: 'Whitney';
    color: black;
    padding: 0px 2rem;
    text-align: center;
    font-size: 1.55rem;
    margin-bottom: 1.5rem;
    text-align: justify;
  }
   a {
    font-family: 'Whitney';
   }
}